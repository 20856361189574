
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPagePageLayout} from 'layouts/CmsPagePageLayout'


const layoutProps = {
  
};
const MDXLayout = CmsPagePageLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`What were you looking for?`}</h2>
    <p>{`Try with:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "index"
        }}>{`Home`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "curriculum-vitae"
        }}>{`Curriculum Vitae`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "tools"
        }}>{`Tools`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "blog-index"
        }}>{`Blog`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "contact"
        }}>{`Contact`}</a></li>
    </ul>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    